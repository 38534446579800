import {
    IonContent,
    IonRow,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonIcon,
    IonText,
    IonInput,
    IonButton,
    IonButtons,
    IonBackButton,
  } from "@ionic/react";
  import React, { useEffect, useState } from "react";
  import DataTable from "react-data-table-component";
import Auth from "../../ModelConn/Auth";

  const ViewHistroyEmp: React.FC<any> = () => {
    const [DataSource1, setDataSource1] = useState<any[]>([]);
    

    function fetchall() {
      fetch(Auth.globalwebhostspc + "/hrapp/historyemp.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // ApiKey: Auth.ApiKey,
        },
        body: JSON.stringify({
          PK_employee: Auth.PK_employee,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setDataSource1(responseJson);
          
        });
      }
  
    useEffect(() => {
      fetchall();
    }, []);
    return (
      <IonPage>
        <IonHeader className="ion-no-boarder">
          <IonToolbar className="tabcolor">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/admindashboard" />
            </IonButtons>
            <IonRow>
              <IonTitle className="ion-text-center">History of Employement</IonTitle>
            </IonRow>
          </IonToolbar>
        </IonHeader>
  
        <IonContent className="ion-padding">
          <IonGrid className="ion-no-margin ion-no-padding">
         
          <DataTable
            title="History of Employment"
            columns={[
              {
                name: "Position",
                selector: (m) => m.eposition,
                sortable: true               
              },            
              {
                name: "Status",
                selector: (m) => m.statustype,
                sortable: true,
                format: (m) => (m.statustype  >=  "1" ? "Probationary" : "Regular"),
              },
              {
                name: "Date",
                selector: (m) => m.DRange,
                sortable: true,
                right: true                
              }              
            ]}
            data={DataSource1}      
            responsive={true}          
            pagination
          />
     
  
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default ViewHistroyEmp;
  