import { loadavg } from "os";

class Auth { 

  constructor() {

    this.globalwebhost = "https://api.spcmc.online";   
    this.globalwebhostspc = "https://link.spcmc.online"; 
    this.authenticated = false;
    this.PK_users = 0;
    this.PK_employee = 0;
    this.FK_dtr = 0; 
    //this.ApiKey = "eyJhbGciOiJIUzI1NiJ9.eyJJc3N1ZXIiOiJTUEMgTUVESUNBTCIsIlVzZXJuYW1lIjoiQWRtaW4ifQ.-_YMiq7fmu9CUMwKoUTnh-Lf-YmBkE3mJcpYIBROzE4"
    
  }


  login(cb, username, password) {
      fetch(this.globalwebhostspc + "/hrapp/login.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", 
        //ApiKey: this.ApiKey,
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.PK_users === 0) {
          this.authenticated = false;
          alert("Login error: " + responseJson.message);
        } else {
          this.authenticated = true;
          this.PK_users = responseJson.PK_users;      
          this.PK_employee = responseJson.PK_employee;   
          this.FK_dtr = responseJson.FK_dtr;       
          cb();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  logout(cb) {
    this.authenticated = false;
    this.PK_users = 0;
    cb();
  }
 
  isAuthenticated() {
    return this.authenticated;
  }

}

export default new Auth();
