import {
  IonAvatar,
  IonBackButton,
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonRadio,
  IonRow,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import {
  logIn,
  peopleCircleOutline,
  peopleOutline,
  pin,
  text,
} from "ionicons/icons";
import { useState, useEffect } from "react";
import Auth from "../../ModelConn/Auth";
import DataTable from "react-data-table-component";
import moment from "moment";

const Leave: React.FC = () => {
  const [DataSource, setDataSource] = useState<any[]>([]);
  const [DataSource1, setDataSource1] = useState<any[]>([]);
  const year = moment().year();
  const year1 = moment().subtract("years", 1).format("YYYY");
  const [Datefrom, setDatefrom] = useState<string>(year1 + "-06-01");
  const [Dateto, setDateto] = useState<string>(year + "-05-31");

  function fetchall() {
    fetch(Auth.globalwebhostspc + "/hrapp/sumleave.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //ApiKey: Auth.ApiKey,
      },
      body: JSON.stringify({
        udatefrom: Datefrom,
        udateto: Dateto,
        PK_employee: Auth.PK_employee,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {   
        setDataSource(responseJson);    
      });

    fetch(Auth.globalwebhostspc + "/hrapp/leavedate.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //ApiKey: Auth.ApiKey,
      },
      body: JSON.stringify({
        udatefrom: Datefrom,
        udateto: Dateto,
        PK_employee: Auth.PK_employee,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setDataSource1(responseJson);
      });
  }

  useEffect(() => {
    fetchall();
  }, [Auth.PK_employee, Dateto, Datefrom]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">

        <IonRow>
            <IonMenuButton />
            {/* <IonMenuToggle autoHide={false}/> */}
            <IonBackButton defaultHref="/mdashboard/info" />
            <IonTitle>Leave Summary</IonTitle>
          </IonRow> 
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel>From Date</IonLabel>
          <IonDatetime
            displayFormat="YYYY-MM-DD"
            value={Datefrom}
            doneText={"SET"}
            onIonChange={(e) => setDatefrom(e.detail.value!)}
          ></IonDatetime>
        </IonItem>
        <IonItem>
          <IonLabel>To Date</IonLabel>
          <IonDatetime
            displayFormat="YYYY-MM-DD"
            value={Dateto}
            doneText={"SET"}
            onIonChange={(e) => setDateto(e.detail.value!)}
          ></IonDatetime>
        </IonItem>

      
        {DataSource.map((m) => (
          <IonCard key={m.PK_employee}>
            <IonCardHeader>
              <IonCardSubtitle>
                {" "}
                <b>{m.fullname}</b>
              </IonCardSubtitle>
              <IonCardTitle>Leave Management</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <IonItem>
                <IonBadge slot="end">{m.bl}</IonBadge>
                <IonText>Birthday Leave (BL) </IonText>
              </IonItem>
              <IonItem>
                <IonText>Emergency Leave (EL)</IonText>
                <IonBadge slot="end">{m.el}</IonBadge>
              </IonItem>
              <IonItem>
                <IonText>Sick Leave (SL) </IonText>
                <IonBadge slot="end">{m.sl}</IonBadge>
              </IonItem>
              <IonItem>
                <IonText>Incentive Leave (IL)</IonText>
                <IonBadge slot="end">{m.il}</IonBadge>
              </IonItem>
              <IonItem>
                <IonText>Service Incentive Leave (SIL)</IonText>
                <IonBadge slot="end">{m.sil}</IonBadge>
              </IonItem>
              <IonItem>
                <IonText>Paternity Leave (PL)</IonText>
                <IonBadge slot="end">{m.pl}</IonBadge>
              </IonItem>
            </IonCardContent>
          </IonCard>
        ))}
        <IonCard>
          <DataTable
            title="Leave Dates"
            columns={[
              {
                name: "Date of Leave",
                selector: (m) => m.sdate,
                sortable: true,
                format: (m) => moment(m.sdate).format("YYYY-MM-DD"),
              },
              {
                name: "Leave Type",
                selector: "leavetype",
                sortable: true,              
              },
              {
                name: "Processed",
                selector: (m) => m.isprocessed,
                sortable: true,
                format: (m) => (m.isprocessed = 1 ? "YES" : "NO"),
              },
              {
                name: "Paid",
                selector: (m) => m.ispaid,
                sortable: true,
                right: true,
                format: (m) => (m.ispaid = 1 ? "YES" : "NO"),
              },
              {
                name: "Leave Reason",
                selector: (m) => m.leavereason,
                sortable: true,
                right: true,                
              },
            ]}
            data={DataSource1}
            defaultSortFieldId="Date of Leave"
            responsive={true}
            // onRowClicked={(m) => alert(m.pK_employee) }

            // sortIcon={<SortIcon />}
            pagination
          />
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Leave;
