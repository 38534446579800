import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { eyeOutline, logIn, logOutOutline, saveOutline } from "ionicons/icons";
import { useState, useEffect } from "react";
import Auth from "../../ModelConn/Auth";
import Moment from "moment";
import "../../pages/Login.css";

const Info: React.FC<any> = ({ history }) => {
  const Datenow = Moment(new Date()).format("LL");

  const [checked, setchecked] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [mobileno, setmobileno] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [email, setemail] = useState("");
  const [DataSource, setDataSource] = useState<any[]>([]);

  const [DataSource2, setDataSource2] = useState<any[]>([]);
  const [picture, setpicture] = useState({ preview: "", raw: "" });

  const [verify, setverify] = useState(false);

  const updateimage = async (e: any) => {
    const fetchAsBlob = (url: any) =>
      fetch(url).then((response) => response.blob());
    const convertBlobToBase64 = (blob: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const blobUrl = picture.preview;

    const blob64 = await fetchAsBlob(blobUrl)
      .then(convertBlobToBase64)
      .then((base64: any) => {
        return base64;
      });
    fetch(Auth.globalwebhostspc + "/hrapp/updateimage.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        picture: blob64,
        PK_user: Auth.FK_dtr,
        isupdonline: 1,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        alert("Successfully Updated");
      });
  };

  function validsize(e: any) {
    if (e.size < 2e6) {
      setpicture({
        preview: URL.createObjectURL(e),
        raw: e,
      });
    } else {
      alert("Please upload a file greather than 2MB");
    }   
    return false;
  }

  function fetchall() {
    fetch(Auth.globalwebhostspc + "/hrapp/empinfo.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // ApiKey: Auth.ApiKey,
      },
      body: JSON.stringify({
        PK_employee: Auth.PK_employee,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setDataSource(responseJson);
        setemail(responseJson[0].emailadd);
        setaddress1(responseJson[0].address1);
        setaddress2(responseJson[0].address2);
        setmobileno(responseJson[0].mobileno);
      });

    fetch(Auth.globalwebhostspc + "/hrapp/loadimage.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PK_user: Auth.FK_dtr,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setDataSource2(responseJson);
      });
  }

  function UpdateInfo() {
    fetch(Auth.globalwebhostspc + "/hrapp/Udtemp.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address1: address1,
        address2: address2,
        mobileno: mobileno,
        emailadd: email,
        PK_employee: Auth.PK_employee,
      }),
    }).then((response) => {
      if (response.status === 200) {
        setShowAlert1(true);
      } else {
        setShowAlert2(true);
      }
    });
  }

  useEffect(() => {
    fetchall();
  }, [picture]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonMenuButton />
            <IonTitle>Employee Information</IonTitle>
            <IonButton color="success"  onClick={() => {
              Auth.logout(() => {
                history.replace("/login");
              })              
            }}>             
          <IonIcon slot="icon-only"  icon={logOutOutline} />
        </IonButton>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      {DataSource.map((m) => (
        <IonContent key={m.PK_employee}>
          <IonCardHeader>
            <IonCardTitle>
              {" "}
              <b> Employee Information</b>
            </IonCardTitle>
          </IonCardHeader>

          <IonCard>
            <IonItem>
              <IonRow>
                <IonCol>
                  <IonList key={m.PK_employee}>
                    {DataSource2.map((m) => (
                      <IonAvatar className="imgavatar">
                        <img
                          src={picture.preview ? picture.preview : m.picture}
                        />
                      </IonAvatar>
                    ))}
                  </IonList>
                </IonCol>

                <IonCol>
                  <IonLabel>
                    <h2>
                      <b>{m.fullname}</b>
                    </h2>
                    <h3>Birthday:{Moment(m.datebirth).format("LL")} </h3>
                    <h3>Gender: {m.gender}</h3>
                    <h3>Date Hired: {Moment(m.datehired).format("LL")}</h3>
                    <h3>Status:{m.empstatus}</h3>
                  </IonLabel>
                </IonCol>
                <IonCol>
                  <IonButton
                    shape="round"
                    type="submit"
                    color="success"
                    onClick={() => {
                      history.push("/viewhisemp");
                    }}
                  >
                    View History of Employment
                    <IonIcon slot="start" icon={eyeOutline} />
                  </IonButton>
                </IonCol>

                <IonCol>
                  <IonText>Update Profile Picture:</IonText>
                  <input
                    type="file"
                    src=""
                    // style={{ display: "none" }}
                    onChange={(e: any) => validsize(e.target.files[0])}
                  />
                  <IonButton
                    shape="round"
                    type="submit"
                    color="success"
                    onClick={() => setverify(true)}
                  >
                    Update Image
                    <IonIcon slot="start" icon={saveOutline} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonItem>
          </IonCard>
          <IonCard>
            <IonItem>
              <IonLabel>
                <h3>Tin Number: {m.num_tin} </h3>
                <h3>SS Number: {m.num_sss}</h3>
                <h3>Pag-Ibig Number: {m.num_hdmf}</h3>
                <h3>PHIC Number: {m.num_phic}</h3>
              </IonLabel>
            </IonItem>
          </IonCard>

          <IonCard>
            <IonItem>
              <IonCheckbox
                checked={checked}
                onIonChange={(e) => setchecked(e.detail.checked)}
              />
              <IonText>Click here to update Information </IonText>
            </IonItem>
            <IonItem hidden={!checked}>
              <IonRow>
                <IonLabel position="stacked"> Update Information</IonLabel>
              </IonRow>
              <IonInput
                placeholder="Address Line 1"
                value={address1}
                onIonChange={(e) => setaddress1(e.detail.value!)}
              ></IonInput>
              <IonInput
                placeholder="Address Line 2"
                value={address2}
                onIonChange={(e) => setaddress2(e.detail.value!)}
              ></IonInput>

              <IonInput
                placeholder="Mobile No:"
                value={mobileno}
                onIonChange={(e) => setmobileno(e.detail.value!)}
              ></IonInput>

              <IonInput
                placeholder="Email:"
                value={email}
                onIonChange={(e) => setemail(e.detail.value!)}
              ></IonInput>
            </IonItem>

            <IonButton
              hidden={!checked}
              shape="round"
              type="reset"
              color="success"
              onClick={UpdateInfo}
            >
              Update
              <IonIcon slot="start" icon={logIn} />
            </IonButton>
          </IonCard>

          <IonAlert
            isOpen={showAlert1}
            onDidDismiss={() => setShowAlert1(false)}
            cssClass="my-custom-class"
            header={"Employee App"}
            message={"You Have Successfully Update the Information!!!"}
            buttons={["OK"]}
          />

          <IonAlert
            isOpen={showAlert2}
            onDidDismiss={() => setShowAlert2(false)}
            cssClass="my-custom-class"
            header={"Employee App"}
            message={"Invalid Response!!!"}
            buttons={["OK"]}
          />

          <IonAlert
            isOpen={verify}
            onDidDismiss={() => setverify(false)}
            cssClass="my-custom-class"
            header={"Confirmation!!"}
            message={"Are you sure you want to update profile picture? "}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
              },
              {
                text: "Okay",
                handler: (e) => {
                  updateimage(e)
                },
              },
            ]}
          />
        </IonContent>
      ))}
    </IonPage>
  );
};

export default Info;
