import {
  
  IonIcon,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
} from "@ionic/react";
import {

  informationCircle,
  calendarClearOutline,  
  logOut,
  cartOutline,  
} from "ionicons/icons";
import { Redirect, Route } from "react-router-dom";
import Auth from "../ModelConn/Auth";
import ProtectedRoute from "../ModelConn/ProtectedRoute";
import Info from "../pages/Mdashboard/Info";
import Leave from "../pages/Mdashboard/Leave";

const MainTab: React.FC<any> = ({ history }) => {
  return (


    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/mdashboard" to="/mdashboard/info" />

        <ProtectedRoute 
        path="/mdashboard/info" 
        component={Info}
        exact={true} />

        <ProtectedRoute
          path="/mdashboard/leave"
          component={Leave}
          exact={true}
        />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="schedule" href="/mdashboard/info">
          <IonIcon icon={informationCircle} />
          <IonLabel>Info</IonLabel>
        </IonTabButton>

        <IonTabButton tab="leave" href="/mdashboard/leave">
          <IonIcon icon={calendarClearOutline} />
          <IonLabel>Leave </IonLabel>
        </IonTabButton>
      
        <IonTabButton tab="Balances" >
          <IonIcon icon={cartOutline} />
          <IonLabel>Balance </IonLabel>
        </IonTabButton>
      
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTab;
