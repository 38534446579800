import { ErrorMessage } from "@hookform/error-message";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { checkboxOutline } from "ionicons/icons";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Redirect } from "react-router-dom";
import LoginContainer from "../components/LoginContainer";
import Auth from "../ModelConn/Auth";
import "./Login.css";


type FormValues = {
  username: string;
  password: string;
};

  const Login: React.FC<any> = ({ history }) => {
  
  const [showModal, setShowModal] = useState(false);
  const [textoutput, settextoutput] = useState("Loading");
  const [checkok, setcheckok] = useState(false);

  const RenderModals = () => {
    return (
      <IonModal isOpen={showModal} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonTitle>Notification</IonTitle>
          <IonRow slot="end"></IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="ion-text-sm-justify ion-padding-horizontal">
          <IonRow>
            <IonCol>
              <pre style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                {textoutput}
              </pre>
            </IonCol>
          </IonRow>

          <IonRow>                
            <IonCol className="ion-text-center">    
            <IonCheckbox      
              checked={checkok}
              onIonChange={(e) => setcheckok((checkok) => !checkok)}            
            />     
              <IonButton
                className="ion-margin "
                type="submit"
                color="success"
                onClick={() =>                 
                  {
                    setShowModal(false)
                    history.replace({
                      pathname: `/mdashboard`,
                    });
                  }
                }
                disabled={!checkok}
              >          
                I Understand / Naiintindihan Ko
                {/* <IonIcon slot="start" icon={checkboxOutline} /> */}
              </IonButton>              
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>    
    );
  };


  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    fetchmesssage();
  }, []);

  const fetchmesssage = () => {
    Promise.all([
      fetch(Auth.globalwebhostspc + "/hrapp/message.php").then((resp) => {
        return resp.text();
      }),
    ])
      .then(([text]) => Promise.all([text]))
      .then(([text]) => {
        settextoutput(text);
      });
  };

  const UserLoginCallback = () => {
    if (Auth.PK_users > 0 && Auth.PK_employee > 0) {
      setShowModal(true);   
    } else if (Auth.PK_employee === null ) {
      alert("Please Contact ITS for Account Activation")
    }
    else {
      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
    }
  };

  const UserLoginFunction = (data: any) => {
    Auth.login(UserLoginCallback, data.username, data.password);
  };

 
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonTitle size="large">Employee APP</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* <LoginContainer /> */}

        <IonGrid className="maingrid ">
        <IonRow>
          <IonCol>
            <img
              src="assets/icon/logoicon.png"
              alt="ion"
              className="imgcontainer"
            ></img>
          </IonCol>
        </IonRow>
        {/* <form onSubmit={handleSubmit((data) => console.log(data))}> */}
        <form onSubmit={handleSubmit((data) => UserLoginFunction(data))}>
          <IonRow>
            <IonCol>
              <IonItem color="light" className="itemlistyle">
                <IonLabel position="floating">Username</IonLabel>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <IonInput
                      onIonChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      spellCheck={false}
                      autocapitalize="off"
                      clearInput
                    ></IonInput>
                  )}
                  control={control}
                  name="username"
                  rules={{
                    required: "This is required field",
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="username"
                  as={<div style={{ color: "red" }} />}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem color="light" className="itemlistyle">
                <IonLabel position="floating">Password</IonLabel>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <IonInput
                      onIonChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      type="password"
                      spellCheck={false}
                      autocapitalize="off"
                      clearInput
                    ></IonInput>
                  )}
                  control={control}
                  name="password"
                  rules={{
                    required: "This is required field",
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  as={<div style={{ color: "red" }} />}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton shape="round" type="submit" color="success">
                Login   
              </IonButton>
            </IonCol>
          </IonRow> 
  
        </form>
      </IonGrid>
        
      </IonContent>

      {/* <IonFooter className="ion-no-boarder">
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonTitle>San Pablo Colleges Medical Center</IonTitle>
          </IonRow>
        </IonToolbar>
      </IonFooter> */}
              {RenderModals()}
    </IonPage>
  );
};

export default Login;
